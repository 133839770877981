import { get, post } from "../utils/Apis";
import { setHeaders } from "../utils/Common";
const BASE_URL = process.env.REACT_APP_API_URL;
const header = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const LoginApi = async (payload, headers = header) => {
  const response = await post(`${BASE_URL}/login`, payload, headers);
  return response;
};

export const VerifyOTPApi = async (payload, headers = header) => {
  const response = await post(`${BASE_URL}/verifyOTP`, payload, headers);
  return response;
};

export const RegisterApi = async (payload, headers = header) => {
  headers = await setHeaders(headers);
  console.log(headers);
  const response = await post(`${BASE_URL}/register`, payload, headers);
  return response;
};

export const getUserByIdApi = async (id, headers = header) => {
  const response = await get(`${BASE_URL}/getUserById/${id}`, headers);
  return response;
};
