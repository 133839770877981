export const getTokenFromLocalStorage = async () => {
  try {
    let getParseToken = JSON.parse(localStorage.getItem("info") ?? "");
    return getParseToken?.accessToken ?? null;
  } catch (error) {
    return null;
  }
};

export const setHeaders = async (headers) => {
  const token = await getTokenFromLocalStorage();
  headers["authorization"] = "Bearer " + token;
  let ledger = localStorage.getItem("currentLedger");
  if (ledger) {
    ledger = JSON.parse(ledger);
    headers["x-ledger-id"] = ledger.id;
  }
  return headers;
};
