import React, { useState } from "react";
import "./Register.css";
import logo from "../../logo-bg-removed.png";
import Alert from "../../components/alert/Alert";
import { RegisterApi } from "../../api/AuthApi";

function Register() {
  const [alertState, setAlertState] = useState({ alertType: "", msg: "" });
  const [userState, setUserState] = useState({ name: "", email: "" });
  const handleChange = (event) => {
    console.log(event.target);
    setUserState({ ...userState, [event.target.name]: event.target.value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (userState.name === "" || userState.email === "") {
      setAlertState({
        alertType: "danger",
        msg: "Please enter name or email!!",
      });
      return;
    }
    const resp = await RegisterApi(userState);
    console.log({ resp });
    if (resp.status) {
      setAlertState({
        alertType: "success",
        msg: resp.msg,
      });
      window.location.href = "/dashboard";
    } else {
      setAlertState({
        alertType: "danger",
        msg: resp.msg,
      });
    }
  };
  return (
    <div className="row">
      <div className="col-md-3 col-sm-1"></div>
      <div className="col-md-6 col-sm-10">
        <div className="card">
          <div className="card-body" style={{ marginBottom: "9%" }}>
            <div className="justify-content-center" align="center">
              <img src={logo} alt="" style={{ width: "20%" }} />
              <h6>VendorTrack</h6>
            </div>
            {alertState.alertType !== "" && (
              <Alert alertType={alertState.alertType} msg={alertState.msg} />
            )}
            <div className="row">
              <div className="col-md-2 col-sm-1"></div>
              <div className="col-md-8 col-sm-10">
                <form action="/register" method="post" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="">Name:</label>
                    <input
                      type="text"
                      placeholder="Enter name"
                      className="form-control"
                      name="name"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="">Email:</label>
                    <input
                      type="email"
                      placeholder="Enter email"
                      className="form-control"
                      name="email"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <button className="btn btn-primary btn-block">
                      Register
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-2 col-sm-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-1"></div>
    </div>
  );
}

export default Register;
