import { deleteApi, get, post, put } from "../utils/Apis";
import { setHeaders } from "../utils/Common";
const BASE_URL = process.env.REACT_APP_API_URL;
const header = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const AddLedgerApi = async (payload, headers = header) => {
  headers = await setHeaders(headers);
  const response = await post(
    `${BASE_URL}/dashboard/ledgers`,
    payload,
    headers
  );
  return response;
};

export const UpdateLedgerApi = async (id, payload, headers = header) => {
  headers = await setHeaders(headers);
  const response = await put(
    `${BASE_URL}/dashboard/ledgers/${id}`,
    payload,
    headers
  );
  return response;
};

export const GetLedgersApi = async (headers = header) => {
  headers = await setHeaders(headers);
  const response = await get(`${BASE_URL}/dashboard/ledgers`, headers);
  return response;
};

export const DeleteLedgersApi = async (id, headers = header) => {
  headers = await setHeaders(headers);
  const response = await deleteApi(
    `${BASE_URL}/dashboard/ledgers/${id}`,
    {},
    headers
  );
  return response;
};
