import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import ledgerReducer from "../features/ledger/ledgerSlice";
import customerReducer from "../features/customer/customerSlice";
import transactionReducer from "../features/transaction/transactionSlice";

export const store = configureStore({
  reducer: {
    authReducer,
    ledgerReducer,
    customerReducer,
    transactionReducer,
  },
});
