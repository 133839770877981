import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AddCustomerApi, UpdateCustomerApi } from "../../api/CustomerApi";
import {
  addCustomer,
  updateCustomer,
} from "../../features/customer/customerSlice";

function Model(props) {
  const { customer, setCustomer } = props;
  console.log(customer);
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const closeBtnRef = useRef(null);
  const handleChange = (e) => {
    if (e.target.name === "image") {
      setImage(e.target.files[0]);
    } else {
      setCustomer({
        ...customer,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleSubmit = async (event) => {
    console.log(customer);
    if (!customer.name) {
      alert("Please Enter Customer Name");
      return;
    }
    if (!customer.mobileNo) {
      alert("Please Enter Customer mobile no");
      return;
    }
    let formData = new FormData();
    formData.append("name", customer.name);
    formData.append("mobileNo", customer.mobileNo);
    formData.append("address", customer.address);
    formData.append("gstIn", customer.gstIn);
    if (image) formData.append("image", image);
    let data = null;
    if (customer.id) {
      data = await UpdateCustomerApi(customer.id, formData, {
        "Content-Type": "multipart/form-data",
      });
    } else {
      data = await AddCustomerApi(formData, {
        "Content-Type": "multipart/form-data",
      });
    }
    console.log(data);
    if (data.status) {
      if (customer.id === "") {
        dispatch(addCustomer(data.data[0]));
        setCustomer({
          id: "",
          name: "",
          image: "",
          mobileNo: "",
          address: "",
          gstIn: "",
        });
      } else dispatch(updateCustomer(data.data[0]));

      closeBtnRef.current.click();
      return;
    }
    alert(data.msg);
  };
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {customer?.id ? "Edit" : "Add"} Customer
            </h5>
            <button
              ref={closeBtnRef}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form action="">
              <div className="form-group">
                <label htmlFor="name">Image: </label>
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  placeholder="Enter Ledger Name"
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Name: </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter Customer Name"
                  required
                  value={customer?.name}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="MobileNo">Mobile No: </label>
                <input
                  type="text"
                  name="mobileNo"
                  placeholder="Enter Mobile No"
                  required
                  onChange={handleChange}
                  className="form-control"
                  value={customer?.mobileNo}
                />
              </div>
              <div className="form-group">
                <label htmlFor="Address">Address: </label>
                <input
                  type="text"
                  name="address"
                  placeholder="Enter Address"
                  required
                  onChange={handleChange}
                  className="form-control"
                  value={customer?.address}
                />
              </div>
              <div className="form-group">
                <label htmlFor="GSTIN">GST IN: </label>
                <input
                  type="text"
                  name="gstIn"
                  placeholder="Enter GST IN"
                  required
                  onChange={handleChange}
                  value={customer?.gstIn}
                  className="form-control"
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Model;
