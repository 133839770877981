import React from "react";
import "./LedgerCard.css";
import logo from "../../logo-bg-removed.png";

function LedgerCard(props) {
  const { ledger, handleDeleteLedger, updateLedger } = props;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const handleVisitLedger = (id) => {
    localStorage.setItem("currentLedger", JSON.stringify(ledger));
    window.location.href = "/customers";
  };
  return (
    <div className="card" style={{ width: "18rem" }}>
      <div className="d-flex justify-content-between">
        <img
          src={ledger?.image ? `${baseUrl}/${ledger.image}` : logo}
          className="card-img-top"
          style={{ width: "40%", borderRadius: "50%" }}
          alt="vendor track logo"
        />
        <div className="mt-3">
          <span
            className="cursor-pointer"
            style={{ margin: "16px" }}
            onClick={() => updateLedger(ledger)}
          >
            <i className="fa-regular fa-pen-to-square"></i>
          </span>
          <span
            className="cursor-pointer"
            style={{ margin: "16px" }}
            onClick={() => handleDeleteLedger(ledger.id)}
          >
            <i className="fa-solid fa-trash" style={{ color: "red" }}></i>
          </span>
        </div>
      </div>
      <hr />
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <h5 className="card-title" align="center">
            {ledger?.name}
          </h5>
          <span>
            <span
              onClick={() => handleVisitLedger(ledger)}
              style={{ cursor: "pointer", color: "blue" }}
            >
              <u>Visit Ledger</u>
            </span>
          </span>
        </div>
        <p>{new Date(ledger.createdAt).toLocaleDateString()}</p>
      </div>
    </div>
  );
}

export default LedgerCard;
