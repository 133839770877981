import React, { useEffect, useState } from "react";
import "./Customer.css";
import CustomerCard from "../../components/customer/CustomerCard";
import SummaryCard from "../../components/customer/SummaryCard";
import Model from "../../components/customer/Model";
import { GetCustomersApi, GetCustomersSummaryApi } from "../../api/CustomerApi";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerData } from "../../features/customer/customerSlice";

function Customer() {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customerReducer.customers);
  const [summary, setSummary] = useState({
    totalLenAmount: 0,
    totalDenAmount: 0,
  });
  const [customer, setCustomer] = useState({
    id: "",
    name: "",
    mobileNo: "",
    address: "",
    gstIn: "",
  });
  const [searchInput, setSearchInput] = useState("");
  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      loadData(searchInput);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [searchInput, 500]);
  async function loadData(search = "") {
    const data = await GetCustomersApi(search);
    if (data.status) {
      console.log(data.data);
      dispatch(setCustomerData(data.data));
    }
  }
  async function loadSummary() {
    const summaryData = await GetCustomersSummaryApi();
    if (summaryData.status && summaryData.data[0] !== null) {
      setSummary(summaryData.data[0]);
    }
  }
  useEffect(() => {
    loadData();
    loadSummary();
  }, []);
  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };
  return (
    <>
      <Model customer={customer} setCustomer={setCustomer} />
      <div className="d-flex justify-content-between mt-3">
        <h5>Customers</h5>
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <i
            className="fa-solid fa-circle-plus"
            style={{ marginRight: "6px" }}
          ></i>
          <span>Customer</span>
        </button>
      </div>
      <hr />
      <SummaryCard summary={summary} />
      <hr />
      <div className="d-flex justify-content-between">
        <input
          className="form-control me-2"
          type="search"
          placeholder="Search customer by name or mobile no"
          aria-label="Search"
          onChange={handleSearchChange}
        />
      </div>
      <hr />
      <h6>Customers</h6>
      <hr />
      {customers.length == 0 && (
        <div className="container d-flex justify-content-center">
          <h4>No Customers found!!</h4>
        </div>
      )}
      {customers?.map((customer) => (
        <div key={customer.id} className="mt-3">
          <CustomerCard key={customer.id} customer={customer} />
        </div>
      ))}
    </>
  );
}

export default Customer;
