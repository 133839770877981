import React from "react";
import "./CustomerCard.css";
import userLogo from "../../user-logo.png";

function CustomerCard(props) {
  const { customer, isSummary } = props;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  console.log(isSummary);
  const totalAmount = customer?.totalLenAmount - customer?.totalDenAmount;
  const transactionColor = totalAmount > 0 ? "green" : "red";

  return (
    <a
      href={!isSummary ? "/customers/" + customer.id : "javascript:void(0)"}
      style={{ textDecoration: "none", color: "black" }}
    >
      <div className="card customer-card">
        <div className="card-body d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={customer?.image ? `${baseUrl}/${customer.image}` : userLogo}
              style={{ width: "40px" }}
              alt=""
            />
            <div style={{ marginLeft: "20px" }}>
              <p>{customer.name}</p>
              <p>{new Date(customer.createdAt).toLocaleDateString()}</p>
            </div>
          </div>
          {isSummary && (
            <div>
              <p>You will {totalAmount > 0 ? "give" : "get"}</p>
              <p className="len-amount-css" style={{ color: transactionColor }}>
                ₹ {Math.abs(totalAmount)}
              </p>
            </div>
          )}
          {isSummary !== true && (
            <>
              <div>
                <p>Len</p>
                <p className="len-amount-css">₹ {customer?.totalLenAmount}</p>
              </div>
              <div>
                <p>Den</p>
                <p className="den-amount-css">₹ {customer?.totalDenAmount}</p>
              </div>
            </>
          )}

          {isSummary !== true && (
            <div className="d-flex align-items-center">
              <i
                className="fa-solid fa-chevron-right cursor-pointer"
                style={{ marginLeft: "1%" }}
              ></i>
            </div>
          )}
        </div>
      </div>
    </a>
  );
}

export default CustomerCard;
