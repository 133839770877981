import React, { useEffect } from "react";
import "./Alert.css";

function Alert(props) {
  useEffect(() => {
    document.getElementById("alert").style.display = "";
    const timeout = setTimeout(() => {
      document.getElementById("alert").style.display = "none";
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <>
      {props.alertType && props.msg && (
        <div
          className={`alert alert-${props.alertType} mt-2`}
          id="alert"
          role="alert"
          key={props.alertType}
        >
          <strong>{props.alertType}!</strong> {props.msg}
        </div>
      )}
    </>
  );
}

export default Alert;
