import { deleteApi, get, post, put } from "../utils/Apis";
import { setHeaders } from "../utils/Common";
const BASE_URL = process.env.REACT_APP_API_URL;
const header = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const AddTransactionApi = async (payload, headers = header) => {
  headers = await setHeaders(headers);
  const response = await post(`${BASE_URL}/transactions`, payload, headers);
  return response;
};

export const UpdateTransactionApi = async (id, payload, headers = header) => {
  headers = await setHeaders(headers);
  const response = await put(
    `${BASE_URL}/transactions/${id}`,
    payload,
    headers
  );
  return response;
};

export const GetTransactionApi = async (id, headers = header) => {
  headers = await setHeaders(headers);
  const response = await get(`${BASE_URL}/transactions/${id}`, headers);
  return response;
};

export const GetTransactionByIdApi = async (id, headers = header) => {
  headers = await setHeaders(headers);
  const response = await get(`${BASE_URL}/transactions/${id}`, headers);
  return response;
};

export const DeleteTransactionApi = async (id, headers = header) => {
  headers = await setHeaders(headers);
  const response = await deleteApi(
    `${BASE_URL}/transactions/${id}`,
    {},
    headers
  );
  return response;
};
