import React, { useEffect, useState } from "react";
import "./CustomerInfo.css";
import { useParams } from "react-router-dom";
import CustomerCard from "../../components/customer/CustomerCard";
import { GetCustomerByIdApi } from "../../api/CustomerApi";
import ReminderCard from "../../components/customer/ReminderCard";
import TransactionCard from "../../components/customer/TransactionCard";
import Model from "../../components/customer/Model";
import TransactionModel from "../../components/customer/TransactionModel";
import { GetTransactionApi } from "../../api/TransactionApi";
import { useDispatch, useSelector } from "react-redux";
import { setTransactionData } from "../../features/transaction/transactionSlice";

function CustomerInfo() {
  const dispatch = useDispatch();
  const transactions = useSelector(
    (state) => state.transactionReducer.transactions
  );
  const { customerId } = useParams();
  const [amount, setAmount] = useState(0);
  const [customer, setCustomer] = useState({
    name: "",
    address: "",
    gstIn: "",
    mobileNo: "",
  });
  async function loadData() {
    const data = await GetCustomerByIdApi(customerId);
    if (!data.status) {
      window.location.href = "/customers";
    }
    setCustomer(data.data[0]);
    const transactionData = await GetTransactionApi(customerId);
    dispatch(setTransactionData(transactionData.data));
  }
  const [type, setType] = useState("gave");
  useEffect(() => {
    loadData();
  }, []);

  const whatsappMessage = (mobileNo) => {
    const url = `https://wa.me/${mobileNo}?text=Hi from vendortrack please install app and manage you ledger with us`;
    return url;
  };

  const smsMessage = (mobileNo) => {
    const url = `sms:${mobileNo}?body=Hi from vendortrack please install app and manage you ledger with us`;
    return url;
  };
  const handleClick = (type) => {
    setType(type);
    setAmount(0);
  };
  return (
    <>
      <Model customer={customer} setCustomer={setCustomer} />
      <TransactionModel
        customer={customer}
        type={type}
        amount={amount}
        setAmount={setAmount}
      />
      <div className="container mt-3">
        <div className="d-flex justify-content-between">
          <h5>Customer Summary</h5>
          <div className="justify-content-between">
            <i
              className="fa-regular fa-pen-to-square cursor-pointer"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              style={{ fontSize: "20px" }}
            ></i>
            <i
              className="fa-solid fa-trash cursor-pointer"
              style={{ marginLeft: "15px", fontSize: "20px", color: "red" }}
            ></i>
          </div>
        </div>
      </div>
      <hr />
      {/* customer info card */}
      <div className="container mt-4">
        <CustomerCard customer={customer} isSummary={true} />
      </div>
      <hr />
      {/* reminder card */}
      <div className="container">
        <ReminderCard
          whatsappMessage={whatsappMessage(customer.mobileNo)}
          smsMessage={smsMessage(customer.mobileNo)}
        />
      </div>
      {/* header for transactions */}
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-3 col-sm-3">
            <p>Entries</p>
          </div>
          <div className="col-md-3 col-sm-3"></div>
          <div className="col-md-3 col-sm-3">
            <div className="d-flex justify-content-center">
              <p>You got</p>
            </div>
          </div>
          <div className="col-md-3 col-sm-3">
            <div className="d-flex justify-content-center">
              <p>You give</p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ minHeight: "100%", paddingBottom: "50px" }}>
        <div className="container">
          {!transactions?.length && (
            <div className="mt-5" align="center">
              <h4>No transactions!!</h4>
            </div>
          )}
          {transactions?.map((transaction) => {
            return (
              <div className="mt-3" key={transaction.id}>
                <TransactionCard
                  key={transaction.id}
                  transaction={transaction}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="container fixed-bottom mt-2 mb-4">
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#addEditTransaction"
            style={{ width: "30%" }}
            onClick={() => handleClick("gave")}
          >
            You Gave ₹
          </button>
          <button
            className="btn btn-danger"
            data-bs-toggle="modal"
            data-bs-target="#addEditTransaction"
            style={{ width: "30%" }}
            onClick={() => handleClick("got")}
          >
            You Got ₹
          </button>
        </div>
      </div>
    </>
  );
}

export default CustomerInfo;
