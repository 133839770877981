import React from "react";
import "./Nav.css";
import logo from "../../logo-bg-removed.png";
import userLogo from "../../user-logo.png";

function Nav() {
  const info = localStorage.getItem("info");
  let isUserLoggedIn =
    info === null || info === undefined || info === "" ? false : true;
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <a className="navbar-brand d-flex align-items-center" href="/">
          <img
            src={logo}
            alt=""
            width="50"
            height="40"
            className="d-inline-block align-text-top"
          />
          <span>VendorTrack</span>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a
                className="nav-link active"
                aria-current="page"
                href="/dashboard"
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/pricing">
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/about">
                About
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contact-us">
                Contact Us
              </a>
            </li>
          </ul>
          {!isUserLoggedIn ? (
            <a href="/login" className="btn btn-outline-primary" type="submit">
              Login/Register
            </a>
          ) : (
            <div className="profile-icon">
              <img src={userLogo} style={{ width: "33px" }} alt="" />
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}
export default Nav;
