import React from "react";
import "./ContactUs.css";

function ContactUs() {
  return (
    <div>
      <h1>Contact us</h1>
    </div>
  );
}

export default ContactUs;
