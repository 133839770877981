import React from "react";
import "./TransactionCard.css";

function TransactionCard(props) {
  const { transaction } = props;
  const transactionColor = transaction.balance > 0 ? "green" : "red";
  return (
    <div className="card transaction-card">
      <div className="card-body">
        <div className="row d-flex justify-content-center">
          <div className="col-md-8 col-sm-8">
            <p>13 July 2024 12:40 AM</p>
            <p className="balance">
              Bal:{" "}
              <span style={{ color: transactionColor }}>
                ₹ {Math.abs(transaction.balance)}
              </span>
            </p>
          </div>
          <div className="col-md-2 col-sm-2">
            <div className="d-flex align-items-center">
              <p className="amount-font-size-weight" style={{ color: "green" }}>
                ₹ {transaction.type === "Credit" ? transaction.amount : "-"}
              </p>
            </div>
          </div>
          <div className="col-md-2 col-sm-2">
            <div className="d-flex align-items-center">
              <p className="amount-font-size-weight" style={{ color: "red" }}>
                ₹ {transaction.type === "Debit" ? transaction.amount : "-"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionCard;
