import React from "react";
import "./ReminderCard.css";

function ReminderCard(props) {
  const { whatsappMessage, smsMessage } = props;
  return (
    <div className="card reminder-card">
      <div className="card-body d-flex justify-content-around">
        <div className="cursor-pointer">
          <i
            className="fa-solid fa-sheet-plastic"
            style={{ fontSize: "31px" }}
          ></i>
          <p>Report</p>
        </div>
        <div className="cursor-pointer">
          <a
            href={whatsappMessage}
            style={{ textDecoration: "none", color: "black" }}
          >
            <i
              className="fa-brands fa-whatsapp"
              style={{ fontSize: "31px" }}
            ></i>
            <p>Whatsapp</p>
          </a>
        </div>
        <div className="cursor-pointer">
          <a
            href={smsMessage}
            style={{ textDecoration: "none", color: "black" }}
          >
            <i
              className="fa-regular fa-comment"
              style={{ fontSize: "31px" }}
            ></i>
            <p>Sms</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ReminderCard;
