import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addLedger, updateLedger } from "../../features/ledger/ledgerSlice";
import { AddLedgerApi, UpdateLedgerApi } from "../../api/DashboardApi";

function Model(props) {
  const { ledger, setLedger } = props;
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const closeBtnRef = useRef(null);
  const handleChange = (e) => {
    if (e.target.name === "image") {
      setImage(e.target.files[0]);
    } else {
      setLedger({
        ...ledger,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleSubmit = async (event) => {
    console.log(ledger);
    if (!ledger.name) {
      alert("Please Enter Ledger Name");
      return;
    }
    let formData = new FormData();
    formData.append("name", ledger.name);
    if (image) formData.append("image", image);
    let data = null;
    if (ledger.id) {
      data = await UpdateLedgerApi(ledger.id, formData, {
        "Content-Type": "multipart/form-data",
      });
    } else {
      data = await AddLedgerApi(formData, {
        "Content-Type": "multipart/form-data",
      });
    }
    console.log(data);
    if (data.status) {
      if (ledger.id === "") dispatch(addLedger(data.data[0]));
      else dispatch(updateLedger(data.data[0]));
      setLedger({ id: "", name: "", image: "" });
      closeBtnRef.current.click();
      return;
    }
    alert(data.msg);
  };
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Ledger
            </h5>
            <button
              ref={closeBtnRef}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form action="">
              <div className="form-group">
                <label htmlFor="name">Name: </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter Ledger Name"
                  required
                  value={ledger?.name}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              {ledger.id && <input type="hidden" name="id" value={ledger.id} />}
              <div className="form-group">
                <label htmlFor="name">Image: </label>
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  placeholder="Enter Ledger Name"
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Model;
