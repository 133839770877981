import React, { useEffect, useState } from "react";
import "./VerifyOtp.css";
import logo from "../../logo-bg-removed.png";
import { useSearchParams } from "react-router-dom";
import { VerifyOTPApi, getUserByIdApi } from "../../api/AuthApi";
import Alert from "../../components/alert/Alert";
import { useDispatch } from "react-redux";
import { addAuthInfo } from "../../features/auth/authSlice";

function VerifyOtp() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [authState, setAuthState] = useState({ mobileNo: "", otp: "" });
  const [alertState, setAlertState] = useState({ alertType: "", msg: "" });
  const id = searchParams.get("id");
  const dispatch = useDispatch();
  const info = localStorage.getItem("info");
  if (info) {
    window.location.href = "/dashboard";
  }
  useEffect(() => {
    async function fetchData() {
      let resp = await getUserByIdApi(id);
      console.log({ resp });
      if (!resp.status) {
        window.location.href = "/login";
        return;
      }
      setAuthState({ ...authState, mobileNo: resp.data[0].mobileNo });
    }
    fetchData();
  }, []);

  const handleChange = (event) => {
    setAuthState({
      ...authState,
      [event.target.name]: event.target.value.trim(),
    });
  };

  const handleSubmit = async (event) => {
    console.log(authState);
    event.preventDefault();
    if (authState.otp === "" || authState.otp.length !== 4) {
      setAlertState({
        alertType: "danger",
        msg: "Please enter valid 4 digit otp!!",
      });
      return;
    }
    const resp = await VerifyOTPApi(authState);
    console.log({ resp });
    if (resp.status) {
      setAlertState({
        alertType: "success",
        msg: resp.msg,
      });

      localStorage.setItem("info", JSON.stringify(resp.data[0]));
      dispatch(
        addAuthInfo({
          authInfo: resp.data[0],
        })
      );
      if (resp.data[0].user.name === null || resp.data[0].user.name === "") {
        window.location.href = "/register?id=" + resp.data[0].user.id;
      } else {
        window.location.href = "/dashboard";
      }
    } else {
      setAlertState({
        alertType: "danger",
        msg: resp.msg,
      });
    }
  };

  return (
    <div className="row">
      <div className="col-md-3 col-sm-1"></div>
      <div className="col-md-6 col-sm-10">
        <div className="card">
          <div className="card-body" style={{ marginBottom: "9%" }}>
            <div className="justify-content-center" align="center">
              <img src={logo} alt="" style={{ width: "20%" }} />
              <h6>VendorTrack</h6>
            </div>
            {alertState.alertType !== "" && (
              <Alert alertType={alertState.alertType} msg={alertState.msg} />
            )}
            <div className="row">
              <div className="col-md-2 col-sm-1"></div>
              <div className="col-md-8 col-sm-10">
                <form action="/register" method="post" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="">OTP:</label>
                    <input
                      type="number"
                      placeholder="Enter OTP"
                      name="otp"
                      onChange={handleChange}
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <button className="btn btn-primary btn-block">
                      Verify OTP
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-2 col-sm-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-1"></div>
    </div>
  );
}

export default VerifyOtp;
