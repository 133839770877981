import "./App.css";
import Nav from "./components/navbar/Nav";
import Router from "./routes/Router";
import { Provider } from "react-redux";
import { store } from "./store/store";

function App() {
  return (
    <Provider store={store}>
      <Nav />
      <div className="container">
        <Router />
      </div>
    </Provider>
  );
}

export default App;
