import React from "react";
import "./SummaryCard.css";

function SummaryCard(props) {
  const { summary } = props;
  return (
    <div className="card mb-3 summary-card">
      <div className="card-body d-flex justify-content-between">
        <div>
          <p className="den-amount-css">₹ {summary.totalDenAmount}</p>
          <p>You will give</p>
        </div>
        <div>
          <p className="len-amount-css">₹ {summary.totalLenAmount}</p>
          <p>You will get</p>
        </div>
        <div className=" d-flex align-items-center justify-content-center">
          <a href="" className="d-flex">
            <span>View Report </span>

            <span className="d-flex justify-content-center align-items-center">
              <i className="fa-solid fa-chevron-right"></i>
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default SummaryCard;
