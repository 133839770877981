import React, { useRef, useState } from "react";
import "./TransactionModel.css";
import { useDispatch } from "react-redux";
import {
  AddTransactionApi,
  UpdateTransactionApi,
} from "../../api/TransactionApi";

import {
  addTransaction,
  updateTransaction,
} from "../../features/transaction/transactionSlice";

function TransactionModel(props) {
  const { customer, type, amount, setAmount } = props;
  const [transaction, setTransaction] = useState({
    description: "",
    dateCreated: new Date(),
  });
  console.log({ transaction, customer });

  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const handleAmountChange = (event) => {
    setAmount(event.target.value ?? 0);
    setTransaction({ ...transaction, amount: event.target.value });
  };

  const handleChange = (event) => {
    if (event.target.name === "image") {
      setImage(event.target.files[0]);
    } else {
      setTransaction({
        ...transaction,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmit = async (event) => {
    console.log(transaction);
    if (!transaction.amount) {
      alert("Amount field is required!!");
      return;
    }
    let formData = new FormData();
    if (image) formData.append("image", image);
    formData.append("amount", transaction.amount);
    formData.append("type", type === "gave" ? "Debit" : "Credit");
    formData.append("customerId", customer.id);
    formData.append("dateCreated", transaction.dateCreated);
    formData.append("description", transaction.description);
    let data = null;
    if (transaction.id) {
      data = await UpdateTransactionApi(transaction.id, formData, {
        "Content-Type": "multipart/form-data",
      });
    } else {
      data = await AddTransactionApi(formData, {
        "Content-Type": "multipart/form-data",
      });
    }
    console.log(data);
    if (data.status) {
      if (customer.id === "") {
        dispatch(addTransaction(data.data[0]));
        setTransaction({});
      } else dispatch(updateTransaction(data.data[0]));
      closeBtnRef.current.click();
      dispatch(addTransaction(data.data[0]));
      alert(data.msg);
      return;
    }
    alert(data.msg);
  };
  const closeBtnRef = useRef(null);
  return (
    <div
      className="modal fade"
      id="addEditTransaction"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              You {type} ₹{amount} to {customer.name}
            </h5>
            <button
              ref={closeBtnRef}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form action="">
              <div className="form-group">
                <label htmlFor="Amount">Amount: </label>
                <input
                  type="number"
                  name="amount"
                  placeholder="Enter amount"
                  required
                  value={amount}
                  className="form-control"
                  onChange={handleAmountChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description: </label>
                <textarea
                  type="text"
                  name="description"
                  placeholder="Enter description"
                  className="form-control"
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="Date">Date: </label>
                <input
                  type="date"
                  name="dateCreated"
                  className="form-control"
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Image: </label>
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  placeholder="Enter Ledger Name"
                  className="form-control"
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionModel;
