import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authInfo: null,
};

export const authSlice = createSlice({
  name: "authInfo",
  initialState: initialState,
  reducers: {
    addAuthInfo: (state, action) => {
      state.authInfo = action.payload;
    },
    removeAuthInfo: (state, action) => {
      state.authInfo = null;
    },
  },
});

export const { addAuthInfo, removeAuthInfo } = authSlice.actions;

export default authSlice.reducer;
