import React, { useState } from "react";
import "./Login.css";
import logo from "../../logo-bg-removed.png";
import { LoginApi } from "../../api/AuthApi";
import Alert from "../../components/alert/Alert";

function Login() {
  const [authState, setAuthState] = useState({ mobileNo: "", otp: "" });
  const [alertState, setAlertState] = useState({ alertType: "", msg: "" });
  const info = localStorage.getItem("info");
  if (info) {
    window.location.href = "/dashboard";
  }
  const handleChange = (event) => {
    setAuthState({
      ...authState,
      [event.target.name]: event.target.value.trim(),
    });
  };

  const handleSubmit = async (event) => {
    console.log(authState);
    event.preventDefault();
    if (authState.mobileNo === "" || authState.mobileNo.length !== 10) {
      setAlertState({
        alertType: "danger",
        msg: "Please enter valid mobile number!!",
      });
      return;
    }
    const resp = await LoginApi({ mobileNo: authState.mobileNo });
    if (resp.status) {
      setAlertState({
        alertType: "success",
        msg: resp.msg,
      });
      window.location.href = "/verify-otp?id=" + resp.data[0].id;
    } else {
      setAlertState({
        alertType: "danger",
        msg: resp.msg,
      });
    }
    console.log({ resp });
  };
  return (
    <div className="row">
      <div className="col-md-3 col-sm-1"></div>
      <div className="col-md-6 col-sm-10">
        <div className="card">
          <div className="card-body" style={{ marginBottom: "9%" }}>
            <div className="justify-content-center" align="center">
              <img src={logo} alt="" style={{ width: "20%" }} />
              <h6>VendorTrack</h6>
            </div>
            {alertState.alertType !== "" && (
              <Alert alertType={alertState.alertType} msg={alertState.msg} />
            )}
            <div className="row">
              <div className="col-md-2 col-sm-1"></div>
              <div className="col-md-8 col-sm-10">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="">Mobile No:</label>
                    <input
                      type="number"
                      placeholder="Enter mobile no"
                      className="form-control"
                      name="mobileNo"
                      onChange={handleChange}
                      pattern="[0-9]{10}"
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <button className="btn btn-primary btn-block">
                      Login/Register
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-md-2 col-sm-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3 col-sm-1"></div>
    </div>
  );
}

export default Login;
