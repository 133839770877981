import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ledgers: [],
};

const ledgerSlice = createSlice({
  name: "ledger",
  initialState,
  reducers: {
    setLedgerData: (state, action) => {
      state.ledgers = action.payload;
    },
    addLedger: (state, action) => {
      state.ledgers.push(action.payload);
    },
    updateLedger: (state, action) => {
      state.ledgers = state.ledgers.map((ledger) => {
        if (ledger.id === action.payload.id) {
          ledger = { ...ledger, ...action.payload };
        }
        return ledger;
      });
    },
    deleteLedger: (state, action) => {
      state.ledgers = state.ledgers.filter(
        (ledger) => ledger.id !== action.payload.id
      );
    },
  },
});

export const { addLedger, deleteLedger, setLedgerData, updateLedger } =
  ledgerSlice.actions;

export default ledgerSlice.reducer;
