import { deleteApi, get, post, put } from "../utils/Apis";
import { setHeaders } from "../utils/Common";
const BASE_URL = process.env.REACT_APP_API_URL;
const header = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const AddCustomerApi = async (payload, headers = header) => {
  headers = await setHeaders(headers);
  const response = await post(`${BASE_URL}/customers`, payload, headers);
  return response;
};

export const UpdateCustomerApi = async (id, payload, headers = header) => {
  headers = await setHeaders(headers);
  const response = await put(`${BASE_URL}/customers/${id}`, payload, headers);
  return response;
};

export const GetCustomersApi = async (search = "", headers = header) => {
  headers = await setHeaders(headers);
  const response = await get(`${BASE_URL}/customers?search=${search}`, headers);
  return response;
};

export const GetCustomerByIdApi = async (id, headers = header) => {
  headers = await setHeaders(headers);
  const response = await get(`${BASE_URL}/customers/${id}`, headers);
  return response;
};

export const GetCustomersSummaryApi = async (headers = header) => {
  headers = await setHeaders(headers);
  const response = await get(`${BASE_URL}/customers/summary`, headers);
  return response;
};

export const DeleteCustomersApi = async (id, headers = header) => {
  headers = await setHeaders(headers);
  const response = await deleteApi(`${BASE_URL}/customers/${id}`, {}, headers);
  return response;
};
