import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/home/Home";
import Login from "../pages/login/Login";
import VerifyOtp from "../pages/verifyOtp/VerifyOtp";
import Register from "../pages/register/Register";
import About from "../pages/about/About";
import ContactUs from "../pages/contactus/ContactUs";
import Pricing from "../pages/pricing/Pricing";
import Dashboard from "../pages/dashboard/Dashboard";
import Logout from "../pages/Logout";
import Customer from "../pages/customer/Customer";
import CustomerInfo from "../pages/customerInfo/CustomerInfo";

function Router() {
  return (
    <div>
      <BrowserRouter>
        <div className="container">
          <Routes>
            <Route path="/" element={<Home />}></Route>
          </Routes>
          <Routes>
            <Route path="/login" element={<Login />}></Route>
          </Routes>
          <Routes>
            <Route path="/about" element={<About />}></Route>
          </Routes>
          <Routes>
            <Route path="/contact-us" element={<ContactUs />}></Route>
          </Routes>
          <Routes>
            <Route path="/pricing" element={<Pricing />}></Route>
          </Routes>
          <Routes>
            <Route path="/verify-otp" element={<VerifyOtp />}></Route>
          </Routes>
          <Routes>
            <Route path="/register" element={<Register />}></Route>
          </Routes>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />}></Route>
          </Routes>
          <Routes>
            <Route path="/customers" element={<Customer />}></Route>
          </Routes>
          <Routes>
            <Route
              path="/customers/:customerId"
              element={<CustomerInfo />}
            ></Route>
          </Routes>
          <Routes>
            <Route path="/logout" element={<Logout />}></Route>
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default Router;
