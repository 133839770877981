import React from "react";
import "./Pricing.css";

function Pricing() {
  return (
    <div>
      <h1>Pricing</h1>
    </div>
  );
}

export default Pricing;
