import React from "react";
import { useDispatch } from "react-redux";
import { removeAuthInfo } from "../features/auth/authSlice";

function Logout() {
  localStorage.removeItem("info");
  const dispatch = useDispatch();
  dispatch(removeAuthInfo({}));
  window.location.href = "/login";
  return <div></div>;
}

export default Logout;
