import React, { useEffect, useRef, useState } from "react";
import LedgerCard from "../../components/dashboard/LedgerCard";
import Model from "../../components/dashboard/Model";
import { useDispatch, useSelector } from "react-redux";
import { DeleteLedgersApi, GetLedgersApi } from "../../api/DashboardApi";
import { deleteLedger, setLedgerData } from "../../features/ledger/ledgerSlice";

function Dashboard() {
  const ledgers = useSelector((state) => state.ledgerReducer.ledgers);
  const [ledger, setLedger] = useState({ id: "", name: "", image: "" });
  const dispatch = useDispatch();
  const addLedgerRef = useRef(null);
  useEffect(() => {
    async function getLedgers() {
      const data = await GetLedgersApi();
      if (data.status) {
        dispatch(setLedgerData(data.data));
        return;
      }
      alert(data.msg);
    }
    getLedgers();
  }, []);

  const updateLedger = (ledger) => {
    setLedger(ledger);
    addLedgerRef.current.click();
  };

  const handleDeleteLedger = async (id) => {
    if (window.confirm("Are you sure want to delete this ledger?")) {
      const data = await DeleteLedgersApi(id);
      if (data.status) {
        dispatch(deleteLedger({ id }));
        return;
      }
      alert(data.msg);
    }
  };
  return (
    <>
      <Model ledger={ledger} setLedger={setLedger} />
      <div className="d-flex justify-content-between mt-3">
        <h5>Ledgers</h5>
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          ref={addLedgerRef}
          data-bs-target="#exampleModal"
        >
          <i
            className="fa-solid fa-circle-plus"
            style={{ marginRight: "6px" }}
          ></i>
          <span>Ledger</span>
        </button>
      </div>
      <hr />
      <div className="row d-flex">
        {!ledgers.length && (
          <div className="d-flex justify-content-center align-item-center mt-5">
            <h4>No Ledgers Found</h4>
          </div>
        )}
        {ledgers?.map((ledger) => (
          <div key={ledger.id} className="col-md-4 col-sm-12 mt-3">
            <LedgerCard
              key={ledger.id}
              ledger={ledger}
              handleDeleteLedger={handleDeleteLedger}
              updateLedger={updateLedger}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default Dashboard;
