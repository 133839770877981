import axios from "axios";

export const get = async (url, headers) => {
  try {
    const resp = await axios.get(url, { headers });
    return resp.data;
  } catch (error) {
    return { status: false, msg: error.message };
  }
};

export const post = async (url, data, headers) => {
  try {
    const resp = await axios.post(url, data, { headers });
    return resp.data;
  } catch (error) {
    console.log(error);
    return { status: false, msg: error.message };
  }
};

export const put = async (url, data, headers) => {
  try {
    const resp = await axios.put(url, data, { headers });
    return resp.data;
  } catch (error) {
    return { status: false, msg: error.message };
  }
};

export const deleteApi = async (url, data, headers) => {
  try {
    const resp = await axios.delete(url, { headers });
    return resp.data;
  } catch (error) {
    return { status: false, msg: error.message };
  }
};
